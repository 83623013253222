<template>
  <section>
    <portal-target name="BreadCrumbs">
      <nav class="breadcrumb" aria-label="breadcrumbs">
        <ul>
          <router-link tag="li" to="/records/customer"><a>Kartoteki</a></router-link>
          <router-link tag="li" to="/records/customer"><a>Klienci</a></router-link>
          <router-link tag="li" :to="{ name: 'CustomerDetails', params: { id: this.$route.params.id }}" class="is-active is-uppercase"><a>{{ name }}</a></router-link>
        </ul>   
      </nav>
    </portal-target>
    <div class="ym-toptitle-box">
      <fixed-header :threshold="119" fixedClass="ym-toptitle-fixed">
        <div  id="toptitle" class="level ym-toptitle">
          <div class="level-left">
            <router-link tag="a" to="/records/customer/" class="button xbtn is-medium is-transparent"><icon name="x"></icon></router-link>
            <h2 class="title level-left is-uppercase">{{ name }}</h2>
          </div>
          <div class='buttons level-right'>
            <button @click="customerRemove" class="button is-medium is-light"><icon name="trash"></icon><span>Usuń</span></button>
            <router-link tag="a" :to="{ name: 'CustomerEdit', params: { id: parseInt(this.$route.params.id) }}" class="button is-medium is-light"><icon name="edit"></icon><span>Edytuj</span></router-link>
            <a v-if="selectACustomer" @click="selectCustomer" class="button is-medium is-dark"><icon name="check"></icon><span>Wybierz</span></a>
          </div>          
        </div>
      </fixed-header> 
    </div> 
    <div class="ym-whitebg pa40" v-if="customer">
        <div class="columns is-variable is-6"  v-if="customer.firstname && customer.lastname">
            <div class="column is-12">
                <p class="label-small">Imię i Nazwisko</p>
                <p class="label">{{ customer.firstname + ' ' + customer.lastname }}</p>
            </div>
        </div>
        <div class="columns is-variable is-6">
            <div class="column is-6" v-if="customer.name">
                <p class="label-small">Pełna nazwa klienta</p>
                <p class="label">{{ customer.name }}</p>
            </div>
            <div class="column is-6" v-if="customer.nip">
                <p class="label-small">NIP</p>
                <p class="label">{{ customer.nip }}</p>
            </div>
        </div>

        <div class="columns is-variable is-6">
            <div class="column is-6" v-if="customer.address">
                <p class="label-small">Adres</p>
                <p class="label">{{ customer.address }}
                <br>{{ customer.postcode }} {{ customer.city }} 
                </p>
            </div>
            <div class="column is-6" v-if="customer.phone">
                <p class="label-small">Telefon</p>
                <p class="label">{{ customer.phone }}</p>
            </div>
        </div>

        <div class="columns is-variable is-6" v-if="customer.email">
            <div class="column is-6">
                <p class="label-small">E-mail</p>
                <p class="label is-lowercase"><a :href="'mailto:'+customer.email">{{ customer.email }}</a></p>
            </div>
        </div>
    </div>  
    <div style="margin-top: 24px" v-if="customer && customer.devices && customer.devices.length > 0">
      <div  id="toptitle" class="level ym-toptitle">
        <h2 class="title level-left">Produkty</h2>
      </div>      
        <b-table
          :data="customer.devices"
          paginated
          >
          <b-table-column label="Nazwa" v-slot="props">
            {{ props.row.name }}      
          </b-table-column>
          <b-table-column label="Typ" v-slot="props">
            {{ props.row.type }}      
          </b-table-column>
          <b-table-column label="Numer seryjny" v-slot="props">
            {{ props.row.serial }}      
          </b-table-column>
          <b-table-column label="Ostatni serwis" v-slot="props">
            {{ props.row.order.repared_at }}      
          </b-table-column>
          <b-table-column label="Ostatnie zlecenie serwisowane"  v-slot="props">
            <router-link style="display: inline-flex;padding: 0 1rem;" tag="a" class="button is-small40 is-dark" :to="{ name: 'ServiceDetails', params: { id: props.row.order.id }}">{{ props.row.order.number }}</router-link>            
          </b-table-column>
         
        </b-table>
    </div>      
  </section>
</template>

<script>
  import FixedHeader from 'vue-fixed-header'
  import {mapActions, mapGetters} from 'vuex'
  import YMmodal from '@/components/Modal.vue';

  export default {
    name: 'CustomerDetails',
    components: {
      FixedHeader
    },
    data: function () {
      return {
        customer: {}
      }
    }, 
    mounted() {
      this.getCustomer()
    },
    computed: {
      ...mapGetters({
        selectACustomer: 'orders/selectACustomer',
        order: 'orders/order'
      }),        
      name: function () {
        return this.customer.name
      }
    },
    methods: {
      ...mapActions({
        getCustomerCall: 'records/getCustomer',
        removeCustomerCall: 'records/removeCustomer',
      }),
      getCustomer() {
        this.getCustomerCall({id: this.$route.params.id})
          .then(resp => this.customer = resp)
          .catch((error) => {
            this.customer = {}
            this.$buefy.toast.open({
              duration: 5000,
              message: error.data.error_description,
              position: 'is-bottom',
              type: 'is-danger'
            })                            
          })
      },
      customerRemove() {
        this.$buefy.modal.open({
          parent: this,
          component: YMmodal,
          hasModalCard: true,
          props: {
            title: 'Czy na pewno chcesz usunąć klienta?',
            content: `<span class='is-danger'>Usunięcia klienta nie można cofnąć.</span> Klient pozostanie w utworzonych do tej pory zleceniach.`,
            cancel: 'Anuluj',
            ymHasIcon: true,
            ymIcon: 'trash',
            submit: `<span class="mal50i">Usuń</span>`,
            ymConfirm: true,
              ymOnConfirm: () => {
                this.removeCustomerCall({id: this.$route.params.id})
                .then(({ data }) => {
                  document.querySelector('.is-cancel').click()
                  this.$router.push({name: 'Customer'})
                  this.$buefy.toast.open({
                      duration: 5000,
                      message: data.alert[1],
                      type: 'is-success',
                      position: 'is-bottom'
                  })  
                })
                .catch(() => {
                  document.querySelector('.is-cancel').click()
                })                 
            }
          }
        }) 
      },
      selectCustomer() {
        this.$store.dispatch('orders/setCustomer', this.customer)
          .then(() => {
              if(this.order && this.order.id) {
                this.$router.push({ name: 'ServiceEdit', params: { id: parseInt(this.order.id) } })
              } else {
                this.$router.push({ name: 'ServiceAdd' })
              }
          })  
      }      
    }    
  }
</script>

<style lang="scss" scoped>

</style>